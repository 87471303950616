
.tit--dot{
    position: relative;
    width: fit-content;
    &__dot{
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: var(--v-primary-base);
        top: -12px;
        right: -12px;
        border-radius: 4px 0 4px 0;
    }
    &.white--text{
        .tit--dot__dot{
            background-color: #fff;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .tit--dot{
        &__dot{
            width: 12px;
            height: 12px;
            top: -20px;
            right: -20px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

