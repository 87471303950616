
// Line
.tit-wrap--line {
    ::v-deep {
        .tit {
            position: relative;
            padding-bottom: 20px;
            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                display: block;
                width: 30px;
                height: 2px;
                background-color: var(--border-color-dark);
            }
            & + .txt {
                margin-top: 20px;
            }
        }
        &.text-center .tit::before {
            left: 50%;
            -webkit-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
        &.text-right .tit::before {
            left: auto;
            right: 0;
        }
        &.text-left .tit::before {
            right: auto;
            left: 0;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .tit-wrap--line {
        ::v-deep {
            .tit {
                padding-bottom: 26px;
                & + .txt {
                    margin-top: 26px;
                }
            }
        }
    }
}
@media (min-width: 1200px) {
}
