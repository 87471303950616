
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){    
    ::v-deep {
        .v-tab {
            &:hover {
                color: var(--tab-active-color) !important;
            }
        }
    }
}
@media (min-width:1200px){
}
